import { Link, BrowserRouter } from 'react-router-dom';
import './index.css';
import logo from '../../image/logo.png'





function Header() {
  return (
    <div className="header header-wrapper">
      <img className="header-logo" src={logo} onClick={() => window.location.href = '/'}></img>
      <div className="header-links">
        {/* <Link to="/">About</Link>
          <Link to="/">Services</Link> */}
        <Link to="/career">Careers</Link>

      </div>
    </div>
  );
}

export default Header;