import './index.css';
import image from '../../image/home.png'
import { Timeline, Tweet } from 'react-twitter-widgets'


const feeds = () => {
    return (
        <>
            <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:6822851060433063936" width="100%" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
            <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:6822850637945024513" width="100%" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
            <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:6818638521729974272" width="100%" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
            <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:6819220318230601728" width="100%" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
            <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:6818639022378897408" width="100%" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
        </>
    )
}

function Home() {

    return (
        <div className="home page-wrapper">
            <div className="top">
                <div className="top-left">
                    <div className="top-left-header">
                        <div>Global Blockchain</div>
                        <div>
                            Consulting &amp; Development
                        </div>
                    </div>
                    <div className="top-left-subtitle">
                        <div >ViewFin™ offers global blockchain research and development solutions that help our clients succeed in the next frontier of Web3.</div>
                        <br></br>
                        <div >We are comprised of a globally distributed team with corporate offices located in Toronto, Canada and Shanghai, China.</div>
                    </div>

                    <a className="top-left-button" href="mailto:info@viewfin.com">Get In Touch</a>
                </div>
                <div className="top-right">
                    <img className="top-right-image" src={image}></img>
                </div>
            </div>
            <div className="bottom">
                <div className="bottom-left social">
                    <div className="bottom-header">
                        Twitter Feed
                    </div>
                    <div className="bottom-left social">
                        <Timeline
                            dataSource={{
                                sourceType: 'infoViewFin',
                                screenName: 'infoViewFin'
                            }}
                        />
                    </div>
                </div>
                <div className="bottom-right social">
                    <div className="bottom-header">
                        LinkedIn Feed
                    </div>
                    <div lassName="bottom-right social">
                        {feeds()}
                    </div>
                    {/* <iframe src='https://www.sociablekit.com/app/embed/63826' frameborder='0' width='100%' height='400'></iframe> */}
                </div>
            </div>
        </div>

    );
}

export default Home;
