import { Link, BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import linkedIn from '../../image/linkedIn.png'
import twitter from '../../image/twitter.png'




function Footer() {
  return (

    <div className="footer footer-wrapper">
      <div className="footer-top">©2021 ViewFin Corporation</div>
      <hr className="footer-hr"></hr>
      <div className="footer-bottom">
        <div className="footer-bottom-links">
          {/* <Link to="/">About</Link>
            <Link to="/">Services</Link> */}
          <Link to="/career">Careers</Link>
        </div>
        <div className="footer-bottom-social">
          <img src={linkedIn} onClick={() => window.location.href = 'https://www.linkedin.com/company/viewfin'}></img>
          <img src={twitter} onClick={() => window.location.href = 'https://twitter.com/infoViewFin'}></img>
        </div>
      </div>
    </div>

  );
}

export default Footer;