
import './index.css';
import image from '../../image/career.png'



function Career() {
    return (
        <div className="career page-wrapper" >
            <img className="img" src={image}></img>
            <div className="career-header">Current Opportunities</div>
            <a href='https://ca.indeed.com/cmp/Viewfin-1/jobs?jk=4ce4cea6536c891b&start=0&clearPrefilter=1'>AWS Engineer Lead</a>
            <a href="mailto:hrcareers@viewfin.com">Please email your resume and the position that you are interested to hrcareers@viewfin.com</a>
        </div>
    );
}

export default Career;
