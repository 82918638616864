
import {
  BrowserRouter,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Header from './components/Header'
import Footer from './components/Footer'
import Home from './pages/Home'
import Career from './pages/Career'
import './index.css';



function App() {

  return (
    <>
      <BrowserRouter >
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/career" component={Career} />
        </Switch>
        <Footer />
      </BrowserRouter>
    </ >
  );
}

export default App;
